import React, { useEffect, useState } from "react";
import {
  fetchTournamentStandings,
  updateTournamentStandings,
} from "../api/api";
import useTokenAndProfile from "./hooks/FetchUserProfile";
import { useUserProfile } from "../context/UserProfileContext";
import useDecodedToken from "../utils/useDecodedToken";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TournamentStandingsComponent = ({ id }) => {
  const { token } = useTokenAndProfile();
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tournamentName, setTournamentName] = useState("");
  const { userProfile } = useUserProfile();
  const [characterName, setCharacterName] = useState(null);
  const { decodedJwt } = useDecodedToken();

  useEffect(() => {
    if (!token) return;

    fetchTournamentStandingsAsync(token, id);
  }, [token, id]);

  const fetchTournamentStandingsAsync = async (token, id) => {
    try {
      const data = await fetchTournamentStandings(token, id);
      setTournamentName(data.tournament.name);
      if (data && Array.isArray(data.standings)) {
        const sortedStandings = data.standings.sort(
          (a, b) => b.mythicPlusScore - a.mythicPlusScore
        );
        setStandings(sortedStandings);
      } else {
        setStandings([]);
      }
    } catch (error) {
      console.error("Error fetching tournament standings:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStandings = async () => {
    try {
      const response = await updateTournamentStandings(token, id);
      toast.success("Successfully updated tournament standings!");
      // Fetch the updated standings
      await fetchTournamentStandingsAsync(token, id);
    } catch (error) {
      console.error("Error updating standings:", error);
      toast.error("Failed to update tournament standings.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (standings.length === 0) {
    return (
      <div className="text-white flex justify-center mt-20 font-bold text-4xl">
        No standings available
      </div>
    );
  }

  const formatDate = (dateString) => {
    const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: false };
    const date = new Date(dateString).toLocaleDateString("en-GB", dateOptions);
    const time = new Date(dateString).toLocaleTimeString("en-GB", timeOptions);
    return `${date} - ${time}`;
  };

  const getClassColor = (characterClass) => {
    const classColors = {
      Hunter: "text-green-500",
      Mage: "text-blue-300",
      Monk: "text-green-200",
      "Death Knight": "text-red-500",
      "Demon Hunter": "text-purple-800",
      Druid: "text-orange-500",
      Paladin: "text-pink-500",
      Rogue: "text-yellow-600",
      Shaman: "text-blue-700",
      Warlock: "text-purple-400",
      Warrior: "text-amber-800",
      Evoker: "text-green-800",
    };
    return classColors[characterClass] || "text-gray-500";
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 pb-16 sm:pb-20">
      <ToastContainer />
      {decodedJwt?.role === "admin" && (
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            onClick={handleUpdateStandings}
          >
            Update tournament standings
          </button>
        </div>
      )}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto lg:flex lg:justify-center mt-10">
          <h1 className="text-4xl font-bold text-center text-white break-words">
            {tournamentName}
          </h1>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-xl font-bold text-gray-900 sm:pl-6"
                    >
                      Character Name
                    </th>
                    <th
                      scope="col"
                      className="hidden lg:table-cell px-3 py-3.5 text-left text-xl font-bold text-gray-900"
                    >
                      Class
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xl font-bold text-gray-900"
                    >
                      Rank
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xl font-bold text-gray-900"
                    >
                      Score
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xl font-bold text-gray-900"
                    >
                      Last Updated
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {standings.map((character, index) => (
                    <tr
                      key={index}
                      className={
                        characterName === character.characterName
                          ? "bg-blue-100"
                          : ""
                      }
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium text-gray-900 sm:pl-6">
                        {character.characterName}
                        {" - "}
                        {character.realm}
                        {character.guildName && (
                          <span className="italic text-gray-600">
                            {" "}
                            ⟨ "{character.guildName}"⟩
                          </span>
                        )}
                      </td>
                      <td className="hidden lg:table-cell whitespace-nowrap px-3 py-4 text-lg font-semibold">
                        <span
                          className={getClassColor(character.characterClass)}
                        >
                          {character.characterClass}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500">
                        {character.rank}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500">
                        {character.mythicPlusScore}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-lg font-semibold text-gray-500">
                        {formatDate(
                          character.raiderIoLastUpdated || character.lastUpdated
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentStandingsComponent;
