import React, { useEffect, useState } from "react";
import { fetchAllTournaments, fetchTournamentStandings } from "../../api/api";
import { useUserProfile } from "../../context/UserProfileContext";
import PastTournamentsList from "../../components/PastTournamentsList";
import useDecodedToken from "../../utils/useDecodedToken";
import ErrorModal from "../../components/modals/ErrorModal";
import Layout from "../../components/Layout";

const PastTournaments = () => {
  const backgroundImage = "/ww-wallpaper.webp";

  const { token } = useDecodedToken();
  const [tournaments, setTournaments] = useState([]);
  const { userProfile } = useUserProfile();

  const [errorMessage, setErrorMessage] = useState("");

  const closeModal = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (!token) return;
    const fetchAllTournamentsAsync = async (token) => {
      try {
        const data = await fetchAllTournaments(token);
        const pastTournaments = data.filter(tournament => tournament.completed);
        setTournaments(pastTournaments);
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      }
    };

    fetchAllTournamentsAsync(token);
  }, [token]);

  return (
    <Layout backgroundImage={backgroundImage}>
      <PastTournamentsList tournaments={tournaments} />
      <ErrorModal errorMessage={errorMessage} closeModal={closeModal} />
    </Layout>
  );
};

export default PastTournaments;