import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../../context/UserProfileContext";
import { fetchAllTournaments } from "../../api/api";
import Layout from "../../components/Layout";
import ErrorModal from "../../components/modals/ErrorModal";
import useTokenAndProfile from "../../components/hooks/FetchUserProfile";
import Loading from "../../components/Loading";
import useProfileStore from "../../stores/profileStore";

function Dashboard() {
  const { token } = useTokenAndProfile();
  const { userProfile: zustandUserProfile, fetchAndSetUserProfile } =
    useProfileStore();
  const navigate = useNavigate();

  const backgroundImage = "/ww-wallpaper.webp";
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [tournaments, setTournaments] = useState([]);
  const { userProfile } = useUserProfile();

  useEffect(() => {
    if (!userProfile && token) {
      fetchAndSetUserProfile(token);
    }
  }, [token, userProfile, fetchAndSetUserProfile]);

  useEffect(() => {
    setLoading(false);
  }, [userProfile]);

  useEffect(() => {
    if (!token) return;

    const fetchAllTournamentsAsync = async () => {
      try {
        const allTournaments = await fetchAllTournaments(token);
        setTournaments(allTournaments);
      } catch (error) {
        console.error("Error fetching tournaments:", error);
        setErrorMessage("Error fetching tournaments");
      }
    };

    fetchAllTournamentsAsync();
  }, [token]);

  useEffect(() => {
    navigate("/tournaments");
  }, [navigate]);

  const closeModal = () => {
    setErrorMessage("");
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Layout backgroundImage={backgroundImage}>
      <ErrorModal errorMessage={errorMessage} closeModal={closeModal} />
    </Layout>
  );
}

export default Dashboard;