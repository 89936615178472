import React from "react";
import Navbar from "./Navbar"; 

const Layout = ({ backgroundImage, children }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      <Navbar />
      {children}
    </div>
  );
};

export default Layout;
