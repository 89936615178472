import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import useDecodedToken from "../utils/useDecodedToken";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useNavigate } from "react-router-dom";
import useTokenAndProfile from "./hooks/FetchUserProfile";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenLg, setDropdownOpenLg] = useState(false);
  const { decodedJwt } = useDecodedToken();
  const battleNetTag = decodedJwt?.battleNetTag
    ? decodedJwt.battleNetTag.split("#")[0]
    : "Characters";
  const dropdownRef = useRef(null);
  const dropdownRefLg = useRef(null);
  const navigate = useNavigate();
  const [mainCharacter, setMainCharacter] = useState({});
  const [activeLink, setActiveLink] = useState("");
  const { token } = useTokenAndProfile();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleDropdownLg = () => {
    setDropdownOpenLg(!dropdownOpenLg);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
    if (
      dropdownRefLg.current &&
      !dropdownRefLg.current.contains(event.target)
    ) {
      setDropdownOpenLg(false);
    }
  };

  const handleLogout = () => {
    Cookies.remove("jwt");
    window.location.href = "/";
  };

  const handleNavigateToDashboard = () => {
    navigate("/dashboard");
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <nav className="bg-gradient-to-r from-gray-400 via-gray-700 to-gray-400 w-full shadow-lg border-b border-gray-700">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo Section - Left */}
          <div
            className="flex-shrink-0 flex items-center cursor-pointer group"
            onClick={handleNavigateToDashboard}
          >
            <div className="bg-white p-1.5 rounded-full mr-3 group-hover:bg-white transition-all duration-300 shadow-lg">
              <img src="/terra_logo.png" alt="Terra Logo" className="h-8 w-8" />
            </div>
            <span className="text-white text-3xl font-bold font-serif tracking-wider group-hover:text-blue-200 transition-colors duration-300">
              TERRA
            </span>
          </div>

          {/* Desktop Navigation - Center */}
          <div className="hidden lg:flex flex-1 justify-center items-center px-16">
            <div className="flex items-center space-x-12 flex-nowrap">
              <NavLink
                href="/account"
                active={activeLink === "characters"}
                onClick={() => handleLinkClick("characters")}
                icon="fas fa-user"
              >
                Characters
              </NavLink>
              <NavLink
                href="/tournaments"
                active={activeLink === "tournaments"}
                onClick={() => handleLinkClick("tournaments")}
                icon="fas fa-trophy"
              >
                Tournaments
              </NavLink>
              <NavLink
                href="/past-tournaments"
                active={activeLink === "past-tournaments"}
                onClick={() => handleLinkClick("past-tournaments")}
                icon="fas fa-history"
              >
                Past Tournaments
              </NavLink>
              {decodedJwt?.role === "admin" && (
                <NavLink
                  href="/manage-tournaments"
                  active={activeLink === "manage-tournaments"}
                  onClick={() => handleLinkClick("manage-tournaments")}
                  icon="fas fa-cog"
                >
                  Manage Tournaments
                </NavLink>
              )}
            </div>
          </div>

          {/* User Profile Section - Right */}
          <div className="hidden lg:flex items-center flex-shrink-0">
            <div className="relative" ref={dropdownRefLg}>
              <button
                onClick={toggleDropdownLg}
                className="flex items-center space-x-3 text-gray-300 hover:text-white px-4 py-2 rounded-lg hover:bg-gray-700/50 transition-all duration-300"
              >
                {mainCharacter.mediaLinks &&
                mainCharacter.mediaLinks.length > 0 ? (
                  <img
                    src={mainCharacter.mediaLinks[0].value}
                    alt={mainCharacter.name}
                    className="h-8 w-8 rounded-full ring-2 ring-blue-500"
                  />
                ) : (
                  <div className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center">
                    <i className="fas fa-user text-white"></i>
                  </div>
                )}
                <span className="font-medium">
                  {mainCharacter.name || battleNetTag}
                </span>
                <i
                  className={`fas fa-chevron-${
                    dropdownOpenLg ? "up" : "down"
                  } text-sm transition-transform duration-300`}
                ></i>
              </button>

              {/* Desktop Dropdown */}
              {dropdownOpenLg && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-gray-800 ring-1 ring-black ring-opacity-5 transform opacity-100 scale-100 transition-all duration-200">
                  <button
                    onClick={handleLogout}
                    className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:text-white hover:bg-gray-700 flex items-center space-x-2"
                  >
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout</span>
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Mobile Menu Button */}
          <div className="lg:hidden flex items-center">
            <button
              onClick={toggleDropdown}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <i
                className={`fas fa-${dropdownOpen ? "times" : "bars"} w-6 h-6`}
              ></i>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation Dropdown */}
      {dropdownOpen && (
        <div className="lg:hidden bg-gray-800 shadow-xl rounded-b-lg">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <MobileNavLink
              href="/account"
              active={activeLink === "characters"}
              onClick={() => handleLinkClick("characters")}
              icon="fas fa-user"
            >
              Characters
            </MobileNavLink>
            <MobileNavLink
              href="/tournaments"
              active={activeLink === "tournaments"}
              onClick={() => handleLinkClick("tournaments")}
              icon="fas fa-trophy"
            >
              Tournaments
            </MobileNavLink>
            <MobileNavLink
              href="/past-tournaments"
              active={activeLink === "past-tournaments"}
              onClick={() => handleLinkClick("past-tournaments")}
              icon="fas fa-history"
            >
              Past Tournaments
            </MobileNavLink>
            {decodedJwt?.role === "admin" && (
              <MobileNavLink
                href="/manage-tournaments"
                active={activeLink === "manage-tournaments"}
                onClick={() => handleLinkClick("manage-tournaments")}
                icon="fas fa-cog"
              >
                Manage Tournaments
              </MobileNavLink>
            )}
            <button
              onClick={handleLogout}
              className="w-full text-left px-4 py-2 text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 rounded-md flex items-center space-x-2"
            >
              <i className="fas fa-sign-out-alt"></i>
              <span>Logout</span>
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

// Desktop Navigation Link Component
const NavLink = ({ href, active, onClick, icon, children }) => (
  <a
    href={href}
    className={`flex items-center space-x-3 px-4 py-2.5 rounded-lg text-base font-medium transition-all duration-300 whitespace-nowrap ${
      active
        ? "text-white bg-blue-500/20 hover:bg-blue-500/30"
        : "text-gray-300 hover:text-white hover:bg-gray-700/50"
    }`}
    onClick={onClick}
  >
    <i className={`${icon} text-blue-400 text-lg`}></i>
    <span>{children}</span>
  </a>
);

// Mobile Navigation Link Component
const MobileNavLink = ({ href, active, onClick, icon, children }) => (
  <a
    href={href}
    className={`flex items-center space-x-2 px-3 py-2 rounded-md text-base font-medium ${
      active
        ? "text-white bg-blue-500/20"
        : "text-gray-300 hover:text-white hover:bg-gray-700"
    }`}
    onClick={onClick}
  >
    <i className={`${icon} ${active ? "text-blue-400" : "text-gray-400"}`}></i>
    <span>{children}</span>
  </a>
);


export default Navbar;