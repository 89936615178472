import CharacterSelection from "../../components/CharacterSelection";
import useTokenAndProfile from "../../components/hooks/FetchUserProfile";
import Layout from "../../components/Layout";

const Account = () => {
  const backgroundImage = "/ww-wallpaper.webp";
  const { token } = useTokenAndProfile();

  return (
    <Layout backgroundImage={backgroundImage}>
      <CharacterSelection token={token} />
    </Layout>
  );
};

export default Account;
