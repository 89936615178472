import React, { useEffect, useState } from "react";
import {
  fetchAllTournaments,
  createTournament,
  deleteTournament,
} from "../../api/api";
import TournamentModal from "../../components/TournamentModal";
import { useNavigate } from "react-router-dom";
import useTokenAndProfile from "../../components/hooks/FetchUserProfile";
import Layout from "../../components/Layout";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManageTournaments = () => {
  const backgroundImage = "/ww-wallpaper.webp";
  const { token } = useTokenAndProfile();
  const [tournaments, setTournaments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      fetchAllTournaments(token)
        .then((data) => {
          setTournaments(data);
        })
        .catch((error) => {
          console.error("Error fetching tournaments:", error);
        });
    }
  }, [token]);

  const handleCreateTournament = (tournament) => {
    if (!token) return;

    const now = new Date();
    const regStart = new Date(tournament.registrationStartDate);
    const regEnd = new Date(tournament.registrationEndDate);
    const startDate = new Date(tournament.startDate);
    const endDate = new Date(tournament.endDate);

    // Validation checks
    if (regStart <= now || regEnd <= now) {
      toast.error("Registration dates must be in the future.");
      return;
    }

    if (regStart >= regEnd) {
      toast.error("Registration start date must be before the end date.");
      return;
    }

    if (regEnd >= endDate) {
      toast.error("Registration end date must be before the tournament end date.");
      return;
    }

    if (startDate >= endDate) {
      toast.error("Tournament start date must be before end date.");
      return;
    }

    // Format the tournament data
    const formattedTournament = {
      name: tournament.name,
      description: tournament.description,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      registrationStartDate: regStart.toISOString(),
      registrationEndDate: regEnd.toISOString(),
    };

    createTournament(token, formattedTournament)
      .then((data) => {
        setTournaments([...tournaments, data]);
        setIsModalOpen(false);
        toast.success("Tournament created successfully!");
      })
      .catch((error) => {
        console.error("Error creating tournament:", error);
        toast.error("Failed to create tournament");
      });
  };

  const handleDeleteConfirm = (tournamentId) => {
    toast(
      ({ closeToast }) => (
        <ConfirmationModal
          onConfirm={() => {
            handleTournamnetDelete(tournamentId);
            closeToast();
          }}
          onCancel={() => {
            closeToast();
          }}
        />
      ),
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      }
    );
  };

  const handleTournamnetDelete = async (id) => {
    if (!token) return;
    try {
      await deleteTournament(token, id);
      setTournaments(tournaments.filter((tournament) => tournament.id !== id));
    } catch (error) {
      console.error("Error deleting tournament:", error);
      toast.error("Error deleting tournament");
    }
  };

  const handleViewRankings = (id) => {
    navigate(`/tournaments/${id}`);
  };

  return (
    <Layout backgroundImage={backgroundImage}>
      <div className="flex flex-col items-center p-4 mt-8">
        <button
          onClick={() => setIsModalOpen(true)}
          className="px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-700 transition duration-300"
        >
          Create Tournament
        </button>
        {tournaments.map((tournament, index) => (
          <div
            key={index}
            className="flex flex-col my-6 lg:flex-row items-center w-[90%] h-auto lg:h-[20vh] bg-gray-300 rounded-lg shadow-lg p-4 transform transition duration-500 lg:hover:scale-105"
          >
            <img
              src={tournament.image || "/wow-wallpaper2.jpg"}
              alt={tournament.name}
              className="w-1/3 h-[12vh] lg:w-[10vw] lg:h-[15vh] rounded-full object-cover mx-4"
            />
            <div className="mt-4 lg:mt-0 flex-grow text-center">
              <h2 className="text-2xl font-bold text-black group-hover:text-white">
                {tournament.name}
              </h2>
              <p className="text-lg text-black group-hover:text-white">
                {tournament.description}
              </p>
              <div className="flex flex-col lg:flex-row justify-center gap-2 lg:gap-4">
                <p className="lg:text-lg text-black group-hover:text-white">
                  Start: {new Date(tournament.startDate).toLocaleDateString()}
                </p>
                <p className="lg:text-lg text-black group-hover:text-white">
                  End: {new Date(tournament.endDate).toLocaleDateString()}
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row mt-4 lg:mt-0">
              <button
                onClick={() => handleViewRankings(tournament.id)}
                className="ml-auto lg:ml-0 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
              >
                Tournament Rankings
              </button>
              <button
                onClick={() => handleDeleteConfirm(tournament.id)}
                className="mt-2 lg:mt-0 lg:ml-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
        <TournamentModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleCreateTournament}
        />
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default ManageTournaments;
