import TournamentStandingsComponent from "../../components/TournamentStandingsComponent";
import Layout from "../../components/Layout";
import { useParams } from "react-router-dom";

function TournamentStandings() {
  const backgroundImage = "/ww-wallpaper.webp";
  const { id } = useParams();

  return (
    <Layout backgroundImage={backgroundImage}>
      <TournamentStandingsComponent id={id} />
    </Layout>
  );
}

export default TournamentStandings;
