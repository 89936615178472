import React, { useEffect, useState } from "react";
import { fetchUserCharacters, signupForTournament } from "../api/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CharactersListModal = ({
  token,
  isOpen,
  onClose,
  tournamentId,
  onSignupSuccess,
}) => {
  const [characters, setCharacters] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleSignup = async (characterId, characterName, characterRealm) => {
    if (!token) return;
    try {
      await signupForTournament(
        token,
        tournamentId,
        characterId,
        characterName,
        characterRealm
      );
      toast.success("Signed up successfully!");
      if (onSignupSuccess) {
        onSignupSuccess(tournamentId);
      }
      onClose();
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (!token) return;

    const fetchCharacters = async () => {
      try {
        const data = await fetchUserCharacters(token);
        setCharacters(data);
      } catch (error) {
        console.error("Error fetching characters:", error);
        toast.error("Error fetching characters");
      } finally {
        setLoading(false);
      }
    };

    fetchCharacters();
  }, [token]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md md:max-w-lg lg:max-w-xl">
        <div className="flex items-center justify-center">
          <h2 className="text-2xl font-bold mb-4">
            Choose Character to sign up
          </h2>
        </div>
        {loading ? (
          <div className="flex items-center justify-center">Loading...</div>
        ) : (
          <ul className="pl-5 my-4 list-none">
            {characters.length > 0 ? (
              characters.map((character) => (
                <li
                  key={character.id}
                  className="my-6 flex flex-col sm:flex-row sm:items-center justify-between gap-2 sm:gap-0"
                >
                  <div className="flex items-start sm:items-center">
                    <img
                      src={character.mediaLinks[0].value}
                      alt={character.name}
                      className="w-8 h-8 mr-4 mt-1 sm:mt-0 rounded-full"
                    />
                    <div className="flex flex-col">
                      <span className="font-semibold">{character.name}</span>
                      <span className="text-gray-600">
                        {character.realm.name}
                      </span>
                    </div>
                  </div>
                  <button
                    onClick={() =>
                      handleSignup(
                        character.id,
                        character.name,
                        character.realm.name
                      )
                    }
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition duration-300 w-full sm:w-auto"
                  >
                    Sign up
                  </button>
                </li>
              ))
            ) : (
              <p>No characters found</p>
            )}
          </ul>
        )}
        <div className="flex justify-end mt-4">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CharactersListModal;
