import { create } from "zustand";
import { fetchUserProfile } from "../api/api";

const useProfileStore = create((set) => {
  const fetchAndSetUserProfile = async (token) => {
    if (!token) {
      console.error("No token provided");
      return;
    }
    try {
      const profile = await fetchUserProfile(token);
      set({ userProfile: profile });
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  return {
    userProfile: null,
    setUserProfile: (profile) => {
      console.error("Setting user profile:", profile);
      set({ userProfile: profile });
    },
    fetchAndSetUserProfile,
  };
});

export default useProfileStore;
