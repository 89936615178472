import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cancelTournamentSignup } from "../api/api";
import useTokenAndProfile from "./hooks/FetchUserProfile";
import { toast, ToastContainer } from "react-toastify";
import CharactersListModal from "./CharactersListModal";
import ConfirmationModal from "./ConfirmationModal";

const TimeDisplay = ({ icon, label, time }) => {
  // Check if the time is "Closed"
  if (time === "Closed") {
    return (
      <div className="bg-gray-100 rounded-lg p-2 text-gray-800 shadow-sm w-[280px]">
        <div className="flex items-center mb-1 justify-center">
          <i className={`${icon} mr-2`}></i>
          <span className="text-gray-600 text-xs">{label}</span>
        </div>
        <div className="flex justify-center py-1">
          <span className="text-red-500 font-semibold text-lg">Closed</span>
        </div>
      </div>
    );
  }

  // Check if this is a Tournament Period display (contains dates)
  if (time.includes("-")) {
    return (
      <div className="bg-gray-100 rounded-lg p-2 text-gray-800 shadow-sm w-[280px]">
        <div className="flex items-center mb-1 justify-center">
          <i className={`${icon} mr-2`}></i>
          <span className="text-gray-600 text-xs">{label}</span>
        </div>
        <div className="flex justify-center py-1">
          <span className="text-gray-800 font-semibold text-sm whitespace-nowrap">
            {time}
          </span>
        </div>
      </div>
    );
  }

  // Regular time display for countdown with D,H,M,S
  const [days, hours, minutes, seconds] = time.split(",").map((t) => t.trim());

  return (
    <div className="bg-gray-100 rounded-lg p-2 text-gray-800 shadow-sm w-[280px]">
      <div className="flex items-center mb-1 justify-center">
        <i className={`${icon} mr-2`}></i>
        <span className="text-gray-600 text-xs">{label}</span>
      </div>
      <div className="flex space-x-4 justify-center">
        <TimeUnit value={days} label="D" />
        <TimeUnit value={hours} label="H" />
        <TimeUnit value={minutes} label="M" />
        <TimeUnit value={seconds} label="S" />
      </div>
    </div>
  );
};

const TimeUnit = ({ value, label }) => (
  <div className="flex flex-col items-center">
    <div className="bg-gray-200 w-8 text-center py-0.5 rounded">
      <span className="font-mono text-sm font-bold">{value}</span>
    </div>
    <span className="text-[10px] text-gray-500 mt-0.5">{label}</span>
  </div>
);

const TournamentsList = ({ tournaments }) => {
  const { token } = useTokenAndProfile();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedTournamentId, setSelectedTournamentId] = useState(null);
  const [countdowns, setCountdowns] = useState({});
  const [localTournaments, setLocalTournaments] = useState(tournaments);

  useEffect(() => {
    setLocalTournaments(tournaments);
  }, [tournaments]);

  // Add useEffect for dynamic countdown
  useEffect(() => {
    const timer = setInterval(() => {
      const newCountdowns = {};
      const now = new Date();

      localTournaments.forEach((tournament) => {
        const startDate = new Date(tournament.startDate);
        const endDate = new Date(tournament.endDate);
        const regStartDate = new Date(tournament.registrationStartDate);
        const regEndDate = new Date(tournament.registrationEndDate);

        if (startDate > now) {
          newCountdowns[`start_${tournament.id}`] =
            calculateTimeDifference(startDate);
        }
        if (endDate > now) {
          newCountdowns[`end_${tournament.id}`] =
            calculateTimeDifference(endDate);
        }
        if (regStartDate > now) {
          newCountdowns[`regStart_${tournament.id}`] =
            calculateTimeDifference(regStartDate);
        }
        if (regEndDate > now) {
          newCountdowns[`regEnd_${tournament.id}`] =
            calculateTimeDifference(regEndDate);
        }
      });

      setCountdowns(newCountdowns);
    }, 1000);

    return () => clearInterval(timer);
  }, [localTournaments]);

  const handleViewRankings = (tournamentId) => {
    navigate(`/tournaments/${tournamentId}`);
  };

  const handleSignup = (tournamentId) => {
    setSelectedTournamentId(tournamentId);
    setIsModalOpen(true);
  };

  const handleSignout = async (id) => {
    if (!token) return;
    setSelectedTournamentId(id);
    setIsConfirmationModalOpen(true);
  };

  const confirmSignout = async () => {
    try {
      await cancelTournamentSignup(token, selectedTournamentId);
      setIsConfirmationModalOpen(false);
      window.location.reload();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSignupSuccess = (tournamentId) => {
    setLocalTournaments((prevTournaments) =>
      prevTournaments.map((tournament) =>
        tournament.id === tournamentId
          ? { ...tournament, isUserRegistered: true }
          : tournament
      )
    );
  };

  // Array of images
  const images = [
    "/wow-wallpaper.jpg",
    "/wow-wallpaper2.jpg",
    "/wow-wallpaper3.jpg",
  ];

  const calculateTimeDifference = (futureDate) => {
    const now = new Date();
    const diff = futureDate - now;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    return `${days}, ${hours}, ${minutes}, ${seconds}`;
  };

  if (localTournaments.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[50vh] px-4">
        <div className="text-center">
          <i className="fas fa-trophy text-white text-6xl sm:text-7xl lg:text-8xl mb-6"></i>
          <p className="text-white text-2xl sm:text-3xl lg:text-5xl font-bold">
            There are no active tournaments
          </p>
          <p className="text-white text-base sm:text-lg lg:text-xl mt-3">
            Check back later for upcoming tournaments
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center p-4">
      {localTournaments.map((tournament, index) => {
        const now = new Date();
        const startDate = new Date(tournament.startDate);
        const registrationStartDate = new Date(
          tournament.registrationStartDate
        );
        const registrationEndDate = new Date(tournament.registrationEndDate);

        const isTournamentInFuture = startDate > now;
        const isRegistrationNotOpen = registrationStartDate > now;
        const isRegistrationClosed = registrationEndDate < now;

        return (
          <div
            key={index}
            className="flex flex-col lg:flex-row items-center w-[90%] min-h-[200px] lg:h-[25vh] bg-gradient-to-r from-gray-200 to-gray-300 rounded-xl shadow-2xl p-6 mt-8 border-l-4 border-blue-500 overflow-hidden"
          >
            <div className="relative shrink-0">
              <img
                src={images[index % images.length]}
                alt={tournament.name}
                className="w-[150px] h-[150px] rounded-xl object-cover shadow-md ring-2 ring-blue-400"
              />
              <div className="absolute -bottom-2 -right-2 bg-blue-500 rounded-full p-2 shadow-lg">
                <i className="fas fa-trophy text-white text-lg"></i>
              </div>
            </div>

            <div className="mt-4 lg:mt-0 flex-grow px-6 text-center flex flex-col min-w-0">
              <h2 className="text-xl font-bold text-gray-800 mb-2 truncate">
                {tournament.name}
              </h2>
              <p className="text-gray-600 mb-3 text-sm line-clamp-2">
                {tournament.description}
              </p>

              <div className="flex flex-col sm:flex-row justify-center items-center space-y-3 sm:space-y-0 sm:space-x-6 max-w-4xl mx-auto">
                {isRegistrationNotOpen ? (
                  <TimeDisplay
                    icon="fas fa-clock text-yellow-500"
                    label="Registration Opens In"
                    time={
                      countdowns[`regStart_${tournament.id}`] ||
                      calculateTimeDifference(registrationStartDate)
                    }
                  />
                ) : !isRegistrationClosed ? (
                  <TimeDisplay
                    icon="fas fa-user-plus text-green-500"
                    label="Registration Closes In"
                    time={
                      countdowns[`regEnd_${tournament.id}`] ||
                      calculateTimeDifference(registrationEndDate)
                    }
                  />
                ) : (
                  <TimeDisplay
                    icon="fas fa-lock text-red-500"
                    label="Registration Status"
                    time="Closed"
                  />
                )}

                {isTournamentInFuture ? (
                  <TimeDisplay
                    icon="fas fa-hourglass-start text-orange-500"
                    label="Tournament Starts In"
                    time={
                      countdowns[`start_${tournament.id}`] ||
                      calculateTimeDifference(startDate)
                    }
                  />
                ) : (
                  <TimeDisplay
                    icon="fas fa-hourglass-end text-red-500"
                    label="Tournament Ends In"
                    time={
                      countdowns[`end_${tournament.id}`] ||
                      calculateTimeDifference(new Date(tournament.endDate))
                    }
                  />
                )}
              </div>
            </div>

            <div className="flex flex-row mt-4 lg:mt-0 gap-2 shrink-0">
              <button
                onClick={() => handleViewRankings(tournament.id)}
                className="px-6 py-2.5 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300 flex items-center justify-center shadow-md"
              >
                <i className="fas fa-list-ol mr-2"></i>
                Rankings
              </button>
              {!isRegistrationNotOpen && !isRegistrationClosed && (
                <>
                  {tournament.isUserRegistered ? (
                    <button
                      onClick={() => handleSignout(tournament.id)}
                      className="px-6 py-2.5 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-300 flex items-center justify-center shadow-md"
                    >
                      <i className="fas fa-sign-out-alt mr-2"></i>
                      Sign out
                    </button>
                  ) : (
                    <button
                      onClick={() => handleSignup(tournament.id)}
                      className="px-6 py-2.5 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300 flex items-center justify-center shadow-md"
                    >
                      <i className="fas fa-sign-in-alt mr-2"></i>
                      Sign up
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        );
      })}
      {isModalOpen && (
        <CharactersListModal
          token={token}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          tournamentId={selectedTournamentId}
          onSignupSuccess={handleSignupSuccess}
        />
      )}
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={confirmSignout}
        title="Confirm Sign Out"
        message="Are you sure you want to sign out from this tournament?"
      />
      <ToastContainer />
    </div>
  );
};

export default TournamentsList;
