import React, { useState } from "react";
import { toast } from "react-toastify";

const TournamentModal = ({ isOpen, onClose, onSubmit }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [registrationStartDate, setRegistrationStartDate] = useState("");
  const [registrationEndDate, setRegistrationEndDate] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const now = new Date();
    const regStart = new Date(registrationStartDate);
    const regEnd = new Date(registrationEndDate);
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Validation checks
    if (regStart <= now || regEnd <= now) {
      toast.error("Registration dates must be in the future.");
      return;
    }

    if (regStart >= regEnd) {
      toast.error("Registration start date must be before the end date.");
      return;
    }

    if (regEnd >= end) {
      toast.error("Registration end date must be before the tournament end date.");
      return;
    }

    if (start >= end) {
      toast.error("Tournament start date must be before end date.");
      return;
    }

    onSubmit({
      name,
      description,
      startDate,
      endDate,
      registrationStartDate,
      registrationEndDate,
    });

    // Reset form
    setName("");
    setDescription("");
    setStartDate("");
    setEndDate("");
    setRegistrationStartDate("");
    setRegistrationEndDate("");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md md:max-w-lg lg:max-w-xl">
        <h2 className="text-2xl font-bold mb-4">Create Tournament</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              rows="3"
              required
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="mb-4">
              <label className="block text-gray-700">
                Registration Start Date
              </label>
              <input
                type="datetime-local"
                value={registrationStartDate}
                onChange={(e) => setRegistrationStartDate(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                Registration End Date
              </label>
              <input
                type="datetime-local"
                value={registrationEndDate}
                onChange={(e) => setRegistrationEndDate(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">
                Tournament Start Date
              </label>
              <input
                type="datetime-local"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Tournament End Date</label>
              <input
                type="datetime-local"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row sm:justify-end justify-center">
            <button
              type="button"
              onClick={onClose}
              className="mr-0 sm:mr-4 mb-2 sm:mb-0 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              Create Tournament
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TournamentModal;
