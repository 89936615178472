import React, { useEffect, useState } from "react";
import { useUserProfile } from "../context/UserProfileContext";
import { fetchUserCharacters } from "../api/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./Loading";

const CharacterCard = ({ character }) => (
  <div className="relative bg-gradient-to-br from-gray-100 to-gray-200 rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 hover:shadow-2xl h-[450px] sm:h-[550px] lg:h-[650px] flex flex-col">
    {/* Character Image Container */}
    <div className="relative h-48 sm:h-64 lg:h-80 overflow-hidden">
      <img
        src={character.mediaLinks[2].value}
        alt={character.name}
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent" />

      {/* Faction Badge */}
      <div
        className={`absolute top-2 sm:top-4 right-2 sm:right-4 p-2 sm:p-2.5 rounded-full shadow-lg ${
          character.faction.name === "Horde" ? "bg-red-500" : "bg-blue-200"
        } w-[40px] h-[40px] sm:w-[52px] sm:h-[52px] flex items-end justify-center overflow-visible`}
      >
        <img
          src={
            character.faction.name !== "Horde"
              ? "/alliance-logo.png"
              : "/horde-logo3.png"
          }
          alt={character.faction.name}
          className={`${
            character.faction.name !== "Horde"
              ? "scale-[1.2] sm:scale-[1.5] -mb-1 sm:-mb-2"
              : "w-6 h-6 sm:w-8 sm:h-8"
          } object-contain transform`}
        />
      </div>
    </div>

    {/* Character Info */}
    <div className="flex-1 p-4 sm:p-6 lg:p-8 flex flex-col">
      <div className="flex flex-col items-center">
        <h2 className="text-xl sm:text-2xl lg:text-3xl font-bold text-gray-800 mb-2 font-serif">
          {character.name}
        </h2>
        {character.guild?.name && (
          <p className="text-blue-600 text-base sm:text-lg font-medium mb-2 sm:mb-4">
            ⟨{character.guild.name}⟩
          </p>
        )}

        {/* Class and Race with Icon */}
        <div className="flex items-center justify-center gap-2 sm:gap-3 mb-3 sm:mb-5 flex-nowrap w-full">
          <span className="px-2 sm:px-3 py-1.5 sm:py-2 bg-gray-700 text-white text-xs sm:text-sm rounded-full whitespace-nowrap">
            {character.playable_class.name}
          </span>
          <span className="px-2 sm:px-3 py-1.5 sm:py-2 bg-gray-700 text-white text-xs sm:text-sm rounded-full whitespace-nowrap">
            {character.playable_race.name}
          </span>
        </div>

        {/* Realm Name */}
        <p className="text-gray-800 text-lg sm:text-base lg:text-lg font-bold mb-0.5 sm:mb-1">
          {character.realm.name}
        </p>
      </div>

      {/* Mythic+ Score */}
      <div className="mt-auto pt-1 sm:pt-2 lg:pt-3 flex items-center justify-center">
        <div className="bg-blue-500 text-white px-4 sm:px-6 lg:px-8 py-2 sm:py-3 lg:py-4 rounded-lg shadow-md">
          <i className="fas fa-star mr-2 sm:mr-3 lg:mr-4 text-lg sm:text-xl"></i>
          <span className="font-bold text-lg sm:text-xl">
            {character.mythicPlusScore}
          </span>
        </div>
      </div>
    </div>
  </div>
);

const CharacterSelection = ({ token }) => {
  const [characters, setCharacters] = useState([]);
  const { setUserProfile } = useUserProfile();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) return;

    const fetchCharacters = async () => {
      try {
        const data = await fetchUserCharacters(token);
        setCharacters(data);
      } catch (error) {
        console.error("Error fetching characters:", error);
        toast.error("Error fetching characters");
      } finally {
        setLoading(false);
      }
    };

    fetchCharacters();
  }, [token]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container mx-auto p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {characters.length > 0 ? (
          characters.map((character) => (
            <CharacterCard key={character.id} character={character} />
          ))
        ) : (
          <p className="text-4xl font-bold text-center text-white">
            No characters found
          </p>
        )}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CharacterSelection;
