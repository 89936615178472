import React, { useEffect, useState } from "react";
import { fetchAllTournaments, fetchTournamentStandings } from "../../api/api";
import { useUserProfile } from "../../context/UserProfileContext";
import TournamentsList from "../../components/TournamentList";
import useDecodedToken from "../../utils/useDecodedToken";
import ErrorModal from "../../components/modals/ErrorModal";
import Layout from "../../components/Layout";

const Tournaments = () => {
  const backgroundImage = "/ww-wallpaper.webp";

  const { token } = useDecodedToken();
  const [tournaments, setTournaments] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const closeModal = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (!token) return;
    const fetchAllTournamentsAsync = async (token) => {
      try {
        const data = await fetchAllTournaments(token);
        const activeTournaments = data.filter(
          (tournament) => !tournament.completed
        );
        setTournaments(activeTournaments);
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      }
    };

    fetchAllTournamentsAsync(token);
  }, [token]);

  return (
    <Layout backgroundImage={backgroundImage}>
      <TournamentsList tournaments={tournaments} />
      <ErrorModal errorMessage={errorMessage} closeModal={closeModal} />
    </Layout>
  );
};

export default Tournaments;
